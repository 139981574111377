import React, { useState, useEffect } from 'react';
import GoogleCaptcha from "../captcha";
import { AssetsPDFiles } from "../../utils/utils";
const Explore = () => {
  const hasPartner = false;
  const [year] = useState(new Date().getFullYear());
  const facebook = "https://www.facebook.com/mysignalsdn/";
  const instagram = "https://www.instagram.com/mysignalsdn/";
  const twitter = "https://www.twitter.com/mysignalsdn";
  const tiktok = "https://www.tiktok.com/@mysignalsdn";
  const linkedin = "https://www.linkedin.com/company/mysignalsdn/";
  const youtube = "https://www.youtube.com/@MySIGNALTelcoInsurance";
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    contact_mode: '',
    remarks: '',
    captcha: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submit button

  const [error, setError] = useState(''); // State for error messages



  const handleRecaptchaChange = (value) => {
    // Handle CAPTCHA value change
    setFormData({ ...formData, 'captcha': value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadTailwindScript = () => {
      // Remove all <link> tags with rel="stylesheet"
      document.querySelectorAll('link[rel="stylesheet"]').forEach(link => link.remove());
      // Remove all inline styles
      document.querySelectorAll('style').forEach(style => style.remove());

      // Inject custom styles for spinner and animation
      const style = document.createElement('style');
      style.innerHTML = `
      
            .recaptcha-container {
              display: inline-block;
              width: 100%;
            }

            .recaptcha-container > div {
              transform: scale(0.77);
              transform-origin: 0 0;
            }

            @media (min-width: 640px) {
              .recaptcha-container > div {
                transform: scale(0.85);
              }
            }

@media (min-width: 1024px) {
  .recaptcha-container > div {
    transform: scale(1);
  }
}

        .slide-in {
          animation: slide-in 1s ease-out;
        }
        @keyframes slide-in {
          from {
            transform: translateY(-20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
     /* Container for centering the spinner */
            .spinner-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh; /* Full viewport height */
            }

            /* The spinner container */
            .loading-spinner {
                border: 8px solid #f3f3f3; /* Light gray background */
                border-top: 8px solid #3498db; /* Blue color */
                border-radius: 50%; /* Make it round */
                width: 80px; /* Spinner width */
                height: 80px; /* Spinner height */
                animation: spin 1.5s linear infinite; /* Spin animation */
            }

            /* Animation keyframes */
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
      `;
      document.head.appendChild(style);

      const script = document.createElement('script');
      script.src = 'https://cdn.tailwindcss.com';
      script.onload = () => {
        setIsLoaded(true); // Set isLoaded to true when the script has loaded
      };
      script.onerror = () => {
        console.error('Failed to load Tailwind CSS');
        setIsLoaded(true); // Set to true to prevent infinite spinner in case of failure
      };
      document.body.appendChild(script);
    };

    // Load Tailwind CSS script
    loadTailwindScript();

    // Clean-up function to remove script if needed
    return () => {
      const script = document.querySelector('script[src="https://cdn.tailwindcss.com"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(''); // Clear previous errors

    try {
      setIsSubmitting(true); // Disable button
      const response = await fetch('/api/v1/explore-form-submission', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitted(true);
        // You can handle additional success actions here
      } else {
        // Parse and display server-side errors
        const errorData = await response.json();

        setError(errorData.message || 'Failed to submit the form, Please Refresh and Try Again.');
      }
    } catch (error) {
      // Handle network or unexpected errors
      setError('An unexpected error occurred. Please try again.');

    } finally {
      setIsSubmitting(false); // Ensure button is re-enabled
    }
  };



  // Display loading spinner until Tailwind CSS is loaded
  if (!isLoaded) {
    return (
      <div class="spinner-container">
        <div class="loading-spinner">Loading..</div>
      </div>
    );
  }
  return (
    <>
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>SIGNAL Explore</title>
      </head>


      <div className="bg-gradient-to-t from-[#2fa487] to-[#629a51] min-h-screen flex flex-col relative">
        <div className="relative grid grid-cols-1 md:grid-cols-3 items-center gap-4 py-4">
          {/* Logo (Left) */}
          <div className="flex justify-center md:justify-start p-4 md:p-6">
            <img
              src="/assets/images/explore.png"
              className="h-84 md:h-84 lg:h-84 xl:h-84 w-auto"
              alt="SIGNAL EXPLORE"
            />
          </div>

          {/* Center Text (visible on larger screens) */}
          <div className="md:flex text-center col-span-2">
            <h1 className="text-3xl font-extrabold leading-none tracking-tight text-[#f1f5f9] md:text-7xl lg:text-7xl dark:text-[#f1f5f9]">
              Discover Malaysia with SIGNAL Explore
            </h1>
          </div>



        </div>






        <div id="hero" class="pt-5 grid lg:grid-cols-2 gap-8 items-center">

          <div class="px-5 sm:px-10 md:px-10 lg:px-20">
            <h1 class="text-4xl font-extrabold tracking-tight text-[#022c22] sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
              <span class="block xl:inline">Unlock a World of </span>
              <span class="block text-ti-600 xl:inline">Exclusive Rewards.</span>
            </h1>
            <p class="mt-5 text-[#f4f4f5] text-1xl sm:text-lg lg:text-2xl md:max-w-3xl my-4">
              Experience the ultimate in travel convenience and savings with <strong class="">SIGNAL Explore</strong> — your key to a truly unforgettable Malaysian adventure.
            </p>
            <ul class="text-[#f4f4f5] text-1xl sm:text-lg lg:text-2xl md:max-w-3xl list-disc list-inside space-y-2">
              <li><strong class="">Exclusive Discounts:</strong> Dive into unique deals at top attractions, dining spots, and shopping destinations across Malaysia.</li>
              <li><strong class="">Complimentary Benefits:</strong> Get a free Touch 'n Go card, Telco SIM card, and comprehensive travel insurance for a worry-free journey.</li>
            </ul>
            <p class="text-[#f4f4f5] text-1xl sm:text-lg lg:text-2xl md:max-w-3xl mt-4">
              <strong className=''>Elevate your Malaysian gateway</strong> with <strong className=''>SIGNAL Explore</strong> — where every moment is enhanced with exceptional savings and effortless convenience. Your adventure starts here!
            </p>
            <div class="relative flex flex-col sm:flex-row sm:space-x-4 mt-6">
              <a href="#contact" class="flex items-center w-full px-6 py-3 mb-3 text-lg text-white inline-block sm:w-auto">

                <button type="button" class="text-[#f4f4f5] bg-gradient-to-t from-green-700 to-emerald-950 hover:from-green-500 hover:to-green-700 focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg  text-2xl sm:text-1xl lg:text-3xl sm:text-base  px-6 py-3 text-center">
                  Launching on 15th November 2024
                </button>
              </a>
            </div>
          </div>

          <div class="w-full lg:w-auto mt-6 lg:mt-0">
            <img
              src="/assets/images/front_card.webp"
              alt="SIGNAL EXPLORE"
              class="w-full h-auto"
            />
          </div>
        </div>



        <main className="flex-grow flex flex-col items-center text-center px-6 py-8">
          <section id="contact" className="w-full max-w-4xl rounded-lg p-8 mx-auto">
            <h2 className="text-2xl sm:text-8xl font-bold mb-6 text-[#f4f4f5]">Contact Us</h2>
            <p className="mb-8 text-md sm:text-xl lg:text-3xl text-[#f4f4f5]">Please complete the form below and we'll get back to you as soon as possible:</p>

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Name Field */}
              <div className="mb-6">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-3  rounded-lg  text-[#020617] placeholder-text-[#020617] focus:outline-none focus:border-blue-500"
                  placeholder="Enter your name"
                  required
                />
              </div>

              {/* Mobile Field */}
              <div className="mb-6">
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  className="w-full px-4 py-3  rounded-lg  text-[#020617] placeholder-text-[#020617] focus:outline-none focus:border-blue-500"
                  placeholder="Enter your mobile number"
                  required
                />
              </div>

              {/* Email Field */}
              <div className="mb-6">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-3  rounded-lg  text-[#020617] placeholder-text-[#020617] focus:outline-none focus:border-blue-500"
                  placeholder="Enter your email"
                  required
                />
              </div>

              {/* Preferred Mode of Contact */}
              <div className="mb-6">
                <label className="text-[#042f2e] block text-lg sm:text-2xl font-semibold mb-2 text-left">Preferred Mode of Contact</label>
                <div className="flex items-center mb-2">
                  <input
                    id="contact_mobile"
                    type="radio"
                    name="contact_mode"
                    value="Mobile"
                    checked={formData.contact_mode === 'Mobile'}
                    onChange={handleChange}
                    className="h-5 w-5 text-blue-500 focus:ring-blue-500 border-gray-300"
                    required
                  />
                  <label htmlFor="contact_mobile" className="ml-3 text-lg font-semibold text-[##022c22]">Mobile</label>
                </div>
                <div className="flex items-center">
                  <input
                    id="contact_email"
                    type="radio"
                    name="contact_mode"
                    value="Email"
                    checked={formData.contact_mode === 'Email'}
                    onChange={handleChange}
                    className="h-5 w-5 text-blue-500 focus:ring-blue-500 border-gray-300"
                    required
                  />
                  <label htmlFor="contact_email" className="ml-3 text-lg font-semibold text-[##022c22]">Email</label>
                </div>
              </div>

              {/* Remarks Field */}
              <div className="mb-6">
                <label className="text-[#042f2e] block text-lg sm:text-2xl font-semibold mb-2 text-left">Remarks</label>
                <textarea
                  id="remarks"
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  rows="4"
                  className="w-full px-4 py-3  rounded-lg  text-[#020617] placeholder-text-[#020617] focus:outline-none focus:border-blue-500"
                  placeholder="Enter your remarks"
                  required
                />
              </div>

              {/* Google Captcha */}
              <div className="mb-6 recaptcha-container">

                <GoogleCaptcha customClass="w-full sm:w-3/4 md:w-2/3 lg:w-1/2 px-4 py-3 rounded-lg text-[#020617] placeholder-text-[#020617] focus:outline-none focus:border-blue-500"
                  onChange={handleRecaptchaChange} />
              </div>

              {/* Submit Button */}
              <div className="text-center">
                {!submitted ? (
                  <button type="submit" disabled={isSubmitting} className="bg-black text-gray-100 px-8 py-3 rounded-lg font-semibold text-lg sm:text-xl">
                    {isSubmitting ? 'SENDING...' : 'SEND'}
                  </button>
                ) : (
                  <div className="text-center">
                    <div className="flex items-center bg-[#022c22] text-white text-md font-bold px-4 py-3" role="alert">
                      <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" /></svg>
                      <p>Message Sent.</p>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </section>
          {error && (
            <div role="alert" className="mt-6">
              <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                Error
              </div>
              <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                <p>{error}</p>
              </div>
            </div>
          )}
        </main>


        <div class="py-6">
          <div class="container mx-auto px-4">
            <div class="text-center mb-6">
              <h2 class="text-lg sm:text-6xl font-bold mb-6 text-[#f4f4f5]">In Collaboration with</h2>
              <div class="flex flex-wrap justify-center gap-4">
                <img alt="TNG Logo" class="h-28 w-28 md:h-48 md:w-auto" src="assets/images/touch_n_go.webp" />
                <img alt="TNG Wallet Logo" class="h-28 w-28 md:h-48 md:w-auto" src="assets/images/touch_n_go_ewallet.webp" />
                <img alt="Tune Protect Logo" class="h-28 w-28 md:h-48 md:w-auto" src="assets/images/tune_5g.webp" />
                <img alt="Tune Talk Logo" class="h-28 w-28 md:h-48 md:w-auto" src="assets/images/tune_protect.webp" />
              </div>
            </div>
          </div>
        </div>


        <section className="mx-auto flex flex-col lg:flex-row justify-center items-center p-8 space-y-8 lg:space-y-0 lg:space-x-8">
          {/* Text Section */}


          {/* Box Section */}
          <div className="lg:w-1/2 bg-white p-8 rounded-lg shadow-md flex flex-col space-y-4">
            <h3 className="text-3xl text-[#022c22] font-bold mb-4 text-center lg:text-left">
              Connect with Our Dedicated Team
            </h3>
            <div className="space-y-4">
              <div className="flex items-start space-x-3">
                <img
                  src="/assets/images/signal.svg"
                  alt="Company Logo"
                  className="w-6 h-6 text-[#022c22]"
                />
                <p className="text-[#022c22] text-1xl">
                  MySignal Marketing Sdn Bhd [201901003462 / 1312788 – U]
                </p>
              </div>

              <div className="flex items-start space-x-3">
                <img
                  src="/assets/images/location.svg"
                  alt="Location Icon"
                  className="w-6 h-6 text-[#022c22]"
                />
                <p className="text-[#022c22] text-1xl">
                  A-15-1, Block A, JayaONE 72A Jalan Prof. Diraja Ungku Aziz 46200
                  Petaling Jaya, Selangor Darul Ehsan, Malaysia
                </p>
              </div>

              <div className="flex items-start space-x-3">
                <img
                  src="/assets/images/email.svg"
                  alt="Email Icon"
                  className="w-6 h-6 text-[#022c22]"
                />
                <a
                  href="mailto:enquiry@mysignal.com.my"
                  className="text-blue-600 hover:underline"
                >
                  enquiry@mysignal.com.my
                </a>
              </div>

              <div className="flex items-start space-x-3">
                <img
                  src="/assets/images/phone.svg"
                  alt="Phone Icon"
                  className="w-6 h-6 text-[#022c22]"
                />
                <p className="text-[#022c22] text-1xl">+603 2779 2419</p>
              </div>

              <div className="flex items-start space-x-3">
                <svg xmlns="http://www.w3.org/2000/svg"  alt="Phone Icon"
                  className="w-6 h-6 text-[#022c22]"   viewBox="0 0 50 50">
                  <path d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z"></path>
                </svg>
                <p className="text-[#022c22] text-1xl">+601 0823 1620</p>
              </div>
            </div>
          </div>

          {/* Image Section */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <img
              src="/assets/images/logo.webp"
              className="h-28 md:h-44 w-auto ml-4 md:ml-8 rounded-lg"
              alt="MYSIGNAL Logo"
            />
          </div>
        </section>
        <div className="fixed bottom-12 right-4 z-50 sm:bottom-8 sm:right-8 md:bottom-6 md:right-6 lg:bottom-4 lg:right-4 xl:bottom-4 xl:right-4">


          <a
            href={`https://wa.me/60108231620`}
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-icon"
          >
            <div className="bg-green-700 p-4 rounded-full shadow-lg ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                className="w-12 h-12 text-white"
                fill="currentColor"
              >
                <path d="M16 0C7.163 0 0 7.163 0 16c0 2.837.736 5.584 2.132 8.001L.001 32l8.12-2.125A15.921 15.921 0 0 0 16 32c8.837 0 16-7.163 16-16S24.837 0 16 0zm7.579 22.528c-.327.917-1.589 1.518-2.75 1.713-.738.121-1.692.217-4.921-.987-4.124-1.62-6.774-5.847-6.981-6.127-.207-.28-1.665-2.217-1.665-4.23 0-2.015 1.049-3.008 1.42-3.427.369-.414.804-.52 1.071-.52.258 0 .517.002.744.014.241.011.563-.091.88.671.327.776 1.116 2.67 1.214 2.867.097.197.172.43.033.694-.138.262-.207.424-.41.644-.207.222-.426.49-.61.657-.207.19-.426.396-.184.78.241.388 1.076 1.764 2.305 2.855 1.589 1.414 2.93 1.855 3.304 2.045.373.19.595.172.817-.104.207-.242.938-1.092 1.19-1.467.241-.38.488-.322.818-.19.329.127 2.11 1 2.473 1.183.363.184.604.276.69.428.086.152.086.879-.241 1.797z" />
              </svg>
            </div>
          </a>
        </div>
        <div class="bg-[#053a05] flex flex-col md:flex-row md:items-center md:justify-between shadow rounded-lg p-4 md:p-6 xl:p-8 my-6 mx-4">
          {/* Copyright Section */}
          <div class="text-[#f4f4f5] mb-6 md:mb-0 flex-1 md:text-left text-center">
            <p class="text-xl">© {year} MySignal Marketing Sdn Bhd. All rights reserved.</p>
          </div>


          <ul class="flex flex-col md:flex-row items-center md:items-start justify-center md:justify-start mr-10 mb-6 md:mb-0 gap-4 md:gap-6">
            <li>
              <a href={AssetsPDFiles("SIGNAL_privacy_notice.pdf", hasPartner)} className="text-xl font-normal text-[#f4f4f5] hover:underline">
                Privacy Notice
              </a>
            </li>
            <li>
              <a href={AssetsPDFiles("SIGNAL_condition_of_use.pdf", hasPartner)} className="text-xl font-normal text-[#f4f4f5] hover:underline">
                Conditions of Use
              </a>
            </li>
          </ul>

          {/* Social Icons */}
          <div class="flex flex-wrap justify-center md:justify-end gap-4 lg:mr-16 xl:mr-16 md:mr-16 md:gap-6">
            <a href={facebook} class="text-[#f4f4f5] text-lg hover:text-green-50">
              <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"></path>
              </svg>
            </a>
            <a href={instagram} class="text-[#f4f4f5] text-lg hover:text-green-50">
              <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd"></path>
              </svg>
            </a>
            <a href={twitter} class="text-[#f4f4f5] text-lg hover:text-green-50">
              <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84">
                </path>
              </svg>
            </a>
            <a href={youtube} class="text-[#f4f4f5] text-lg hover:text-green-50">
              <svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="h-5 w-5" fill="currentColor" aria-hidden="true">
                <path fill="currentColor"
                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z">
                </path>
              </svg>
            </a>
            <a href={tiktok} class="text-[#f4f4f5] text-lg hover:text-green-50">
              <svg class="h-5 w-5" fill="currentColor" aria-hidden="true" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><title>TikTok icon</title><path d="M12.53.02C13.84 0 15.14.01 16.44 0c.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"></path></g></svg>
            </a>
            <a href={linkedin} class="text-[#f4f4f5] text-lg hover:text-green-50">
              <svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-5 w-5" fill="currentColor" aria-hidden="true">
                <path fill="currentColor"
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                </path>
              </svg>
            </a>
          </div>
        </div>


      </div>


    </>
  );
};

export default Explore;

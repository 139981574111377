import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";

import GoogleTagManager from "./GoogleTagManager";

import HomePage from "./pages/home";
import Loginpage from "./pages/login";
import AboutPage from "./pages/about";
import DashboardPage from "./pages/dashboard";
import Registrationpage from "./pages/registration";
// import TestPage from "./pages/test";
import ForgetPasswordPage from "./pages/forget-password";
import PrivacyPage from "./pages/privacy";

import ConfirmationPage from "./pages/confirmation";
import ResetPasswordPage from "./pages/reset-password";
import InvoicePDF from "./pages/InvoicePDF";
import ProtectedRoute from "./protectedRoutes/PrivateRoute";
import PaymentConfirmationPage from "./pages/paymentReturn";
import TestPage from "./pages/staging";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import InfluencerPage from "./pages/influencer";
import InfluencerDashboardPage from "./pages/influencerDashboard";
import CareerTeam from "./pages/partner-team";
import CareerPartner from "./pages/partner-careers";
import InfluencerLoginPage from "./pages/InfluencerLoginPage";
import ViewInvoicePage from "./pages/ViewInvoicePage";

import Explore from "./components/explore";


function App() {
 
 
 
  return (
    <>
     <GoogleTagManager />
      <BrowserRouter>
        <Routes>
          <Route path="/:slug?" element={<HomePage />} />
          <Route
            exact
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/pdf"
            element={
              <ProtectedRoute>
                <InvoicePDF />
              </ProtectedRoute>
            }
          />
           <Route path="/view-invoice/:invoice_no" element={<ViewInvoicePage />} />
          <Route path="/our-team" element={<CareerTeam />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/our-partner" element={<CareerPartner />} />
          <Route
            path="/payment-processing"
            element={<PaymentConfirmationPage />}
          />
          <Route path="/confirmation" element={<ConfirmationPage />} />
          <Route path="/partners/:slug" element={<InfluencerPage />} />
          <Route
            path="/partners/login/:slug"
            element={<InfluencerLoginPage />}
          />
          <Route
            path="/partners/dashboard/:slug"
            element={<InfluencerDashboardPage />}
          />
          <Route path="/privacy" element={<PrivacyPage />} />
          {/* <Route path="/terms" element={<TermsPage />} /> */}
          <Route path="/login" element={<Loginpage />} />
          <Route path="/eghl-staging" element={<TestPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/forget-password" element={<ForgetPasswordPage />} />
          <Route path="/registration/:title" element={<Registrationpage />} />
          <Route
            path="/registration/partner/:slug/:title"
            element={<Registrationpage />}
          />
        </Routes>
      </BrowserRouter>
      <div>
       
        <TawkMessengerReact
          propertyId="662cb3301ec1082f04e7f6c1"
          widgetId="1hsf9nu7i"
         
        />
      </div>
    </>
  );
}

export default App;

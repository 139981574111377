// GoogleCaptcha.js

import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const GoogleCaptcha = ({ onChange, customClass = "" }) => {
  const recaptchaRef = useRef(null);

  const handleCaptchaChange = (value) => {
    // Pass the value to the parent component
    onChange(value);
  };

  const handleExpired = () => {
  
    // Reset reCAPTCHA component
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleError = () => {
    // Reset reCAPTCHA component
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  return (
    <div className={customClass}>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={"6Le3DYApAAAAAH0dwnngAXSGBTRsQ48YX50NdfcT"}
        onChange={handleCaptchaChange}
        onExpired={handleExpired}
        onErrored={handleError}
      />
    </div>
  );
};

export default GoogleCaptcha;
